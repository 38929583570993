export const runFinishedStates = [
  "requires_action",
  "cancelled",
  "failed",
  "completed",
  "expired",
];
export const interactModels = {
  phone: "phone",
  chat: "chat",
  training: "training",
};

export const modelMapping = {
  phone: "Proto Trainer Phone",
  chat: "Proto Trainer Chext",
  training: "New Mexico Training Guide",
};

export const starters = {
  phone: [
    "Please quiz me on the knowledge base.",
    "Ask me what role play to do.",
    "Ask me which topic to practice.",
    "I have a question.",
  ],

  chat: [
    "Suggest a chext role play.",
    "Ask me for a role play topic",
    "I have a question.",
  ],
};

export const macStates = {
  ON: "on",
  OFF: "off",
};

export const defaultMacState = macStates.OFF;

export const defaultModel = "phone";
