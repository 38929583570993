import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaMicrophoneLines, FaMicrophoneLinesSlash } from "react-icons/fa6";
import { RiChatHistoryLine } from "react-icons/ri";
import { macStates } from '../hooks/constants';
import SecondarySelector from './SecondarySelector';

const Sidebar = ({ isOpen, onModelChange, onMacStateChange, fetchHistoryByThread, isNewThread }) => {
  const [macState, setMacState] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [historyThreads, setHistoryThreads] = useState([]);
  const [currentHistoryThread, setCurrentHistoryThread] = useState(null);

  useEffect(() => {
    const storageHistory = localStorage.getItem("historyThreads");
    if (storageHistory) {
      setHistoryThreads(JSON.parse(storageHistory));
      setCurrentHistoryThread(JSON.parse(storageHistory)[0].thread_id);
    }
    const macStateFromStorage = localStorage.getItem("macState");
    if (macStateFromStorage) {
      setMacState(macStateFromStorage);
    }
  }, [isNewThread]);

  const handleMacStateChange = (macState) => {
    onMacStateChange(macState);
    setMacState(macState);
  };

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString();
    return formattedDate;
  };

  let historyThread = (thread) => {
    return (
      <li key={thread.thread_id} onClick={() => { fetchHistoryByThread(thread.thread_id); setCurrentHistoryThread(thread.thread_id) }}
        className={'text-sm text-bold text-gray-500 flex items-center mt-2 cursor-pointer hover:bg-gray-100' +
          (currentHistoryThread === thread.thread_id ? ' bg-yellow-50' : '')}>
        <RiChatHistoryLine className='mr-2 text-lg' /> {convertTimestamp(thread.created_at)}
      </li>
    )
  }

  return (
    <div className={`fixed top-0 left-0 h-full w-64 bg-white flex flex-col flex-grow transition duration-500 ease-in-out text-black ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      {/* Logohere */}
      <div className="p-4 flex h-30">
        <div className="sidebar-logo">
          <img className="h-18" src="./logo-main.svg" alt="logo"></img>
        </div>
      </div>

      {/* Sidebar Selector here */}
      <SecondarySelector onModelChange={onModelChange} />

      {/* History here */}
      <div className="flex-grow overflow-y-auto m-4">
        <p className="text-md bold">Recent</p>

        <div>
          <ul className="px-1 py-1">
            {
              historyThreads?.slice(0, 5).map((thread) => (
                historyThread(thread)
              ))
            }
          </ul>
        </div>
        {
          historyThreads?.length > 5 &&
          <button className="text-sm hover:text-pink-500 text-black font-bold mt-2 py-1 px-1 pointer flex items-center"
            onClick={() => { setShowHistory(!showHistory) }}>
            {showHistory ? <FaChevronUp className='mr-5' /> : <FaChevronDown className='mr-5' />}
            {showHistory ? <span>Show less</span> : <span>Show more</span>}
          </button>
        }

        {showHistory && <div className="flex flex-col">
          {
            historyThreads?.slice(5).map((thread) => (
              historyThread(thread)
            ))
          }
        </div>
        }
      </div>
      <div className="justify-end flex items-center justify-center m-4">
        <button
          className={"rounded font-bold p-4 " +
            (macState === macStates.ON ? 'text-pink-500' : 'text-slant-500')
          }>
          {macState === macStates.ON ? <div className='flex flex-col items-center gap-2' onClick={() => handleMacStateChange(macStates.OFF)}><FaMicrophoneLines className='text-3xl' /><p className='text-xs'>Guide Mic On</p></div> : <div className='flex flex-col items-center gap-2' onClick={() => handleMacStateChange(macStates.ON)}><FaMicrophoneLinesSlash className='text-3xl' /><p className='text-xs'>Guide Mic Off</p></div>}
        </button>
      </div>
      {/* TODO: Account Feature will be added later */}
      {/* <button
          className="bg-slate-500 width-500 hover:bg-pink-500 text-white font-bold py-2 px-4 rounded flex items-center space-evenly">
          <MdOutlineManageAccounts className='text-2xl mr-2' />Account
        </button> */}
    </div>
  )
}

export default Sidebar