import { useEffect, useRef } from 'react';

const useKeys = (returnCallback) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && event.shiftKey) {
        return;
      }
      if (event.key === 'Enter') {
        returnCallback();
        return;
      }
    };

    const input = inputRef.current;
    if (input) {
      input.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (input) {
        input.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [returnCallback]);

  return inputRef;
};

export default useKeys;
