import React, { useEffect, useState } from "react";
import "./App.css";
import ChatInput from "./components/ChatInput";
import ChatMessage from "./components/ChatMessage";
import ChatStatusIndicator from "./components/ChatStatusIndicator";
import Loading from "./components/Loading";
import Sidebar from "./components/Sidebar";
import Tooltip from "./components/Tooltip";
import { defaultMacState, defaultModel } from "./hooks/constants";
import { useRunPolling } from "./hooks/useRunPolling";
import { useRunRequiredActionsProcessing } from "./hooks/useRunRequiredActionsProcessing";
import { useRunStatus } from "./hooks/useRunStatus";
import { useThread } from "./hooks/useThread";
import { fetchSpeech, postMessage } from "./services/api";

function App() {
  const [selectedModel, setSelectedModel] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [macState, setMacState] = useState("");

  useEffect(() => {
    // Immediately check local storage for the selected model upon component mount
    const modelFromStorage = localStorage.getItem("selectedModel");
    if (modelFromStorage) {
      setSelectedModel(modelFromStorage);
    } else {
      // Set a default model if none is found in local storage
      setSelectedModel(defaultModel);
      localStorage.setItem("selectedModel", defaultModel);
    }
    // always set the mic off at the app initialization
    setMacState(defaultMacState);
    localStorage.setItem("macState", defaultMacState);
  }, []);

  const handleModelChange = (model) => {
    setSelectedModel(model);
    localStorage.setItem("selectedModel", model);
    clearThread();
  };

  const handleMacChange = (macState) => {
    setMacState(macState);
    localStorage.setItem("macState", macState);
  };

  const getSpeech = async (threadId, audioContent) => {
    if (!threadId || !audioContent) {
      console.error("Missing required parameters");
      return;
    }

    try {
      const audioBlob = await fetchSpeech(threadId, audioContent);
      if (!audioBlob) {
        console.error("No audio blob received");
        return;
      }

      if (!window.audio) {
        window.audio = new Audio();
        window.audio.type = "audio/mp3";
      }

      if (window.audio.src) {
        URL.revokeObjectURL(window.audio.src);
      }
      
      window.audio.src = URL.createObjectURL(audioBlob);
      window.audio.play();
    } catch (error) {
      console.error("Failed to fetch or play audio:", error);
    }
  };

  const [run, setRun] = useState(undefined);
  const {
    threadId,
    messages,
    setMessages,
    setActionMessages,
    clearThread,
    fetchHistoryThread,
  } = useThread(run, setRun, getSpeech, setDataProcessing);

  useRunPolling(threadId, run, setRun);
  useRunRequiredActionsProcessing(run, setRun, setActionMessages);
  const { status, processing } = useRunStatus(run);

  let messageList = messages
    .toReversed()
    .filter((message) => message.hidden !== true)
    .map((message) => {
      return (
        <ChatMessage
          message={message.content}
          role={message.role}
          key={message.id}
        />
      );
    });

  let isNewThread = threadId !== localStorage.getItem("thread_id");

  // Scroll to the bottom of the message list
  useEffect(() => {
    if (messageList.length > 0) {
      const targetDiv = document.getElementById("msgDiv").firstChild;
      if (!targetDiv) {
        return;
      }
      targetDiv.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling
    }
  }, [messageList]);

  const handleSendMessage = (message) => {
    if (message === "") {
      return;
    }
  
    if (window.audio) {
      window.audio.pause();
    }
  
    // Add the user's input into the conversation list
    const inputMsg = [{ content: message, role: "user", id: "user-response", hidden: false }];
    const newMessages = [...messages, ...inputMsg];
    setMessages(newMessages);
    postMessage(threadId, message).then(setRun);
  };

  return (
    <div className="flex h-screen space-between">
      {/* Hamburger icon */}
      <button
        className="fixed top-4 left-4 hover:bg-pink-400 text-white rounded-full p-2 transition duration-400 ease-in-out"
        onClick={openSidebar}
      >
        <svg
          className="block h-5 w-5 fill-current"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>
      {/* New chat icon */}
      <div className="fixed top-4 right-4">
        <Tooltip content="New Chat">
          <button
            className="hover:bg-pink-400 text-white rounded-full p-1"
            onClick={clearThread}
          >
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0" />
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                {" "}
                <title />{" "}
                <g id="Complete">
                  {" "}
                  <g data-name="add" id="add-2">
                    {" "}
                    <g>
                      {" "}
                      <line
                        fill="none"
                        stroke="#ffffff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        x1="12"
                        x2="12"
                        y1="19"
                        y2="5"
                      />{" "}
                      <line
                        fill="none"
                        stroke="#ffffff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        x1="5"
                        x2="19"
                        y1="12"
                        y2="12"
                      />{" "}
                    </g>{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>
          </button>
        </Tooltip>
      </div>

      {/* Sidebar */}
      <Sidebar
        isOpen={isSidebarOpen}
        onModelChange={handleModelChange}
        onMacStateChange={handleMacChange}
        fetchHistoryByThread={fetchHistoryThread}
        isNewThread={isNewThread}
      />
      {/* Main content */}
      <div className="flex flex-col flex-grow" onClick={closeSidebar}>
        <div className="flex-grow overflow-y-auto px-4 md:mx-auto mt-16 pb-20 lg:px-32">
          <div className="flex flex-col-reverse" id="msgDiv">
            {messageList}
          </div>
          {processing && <Loading />}
          {status !== undefined && <ChatStatusIndicator status={status} />}
          {dataProcessing && <Loading />}
          {dataProcessing && <ChatStatusIndicator status="Data Processing" />}
        </div>

        {/* Input field */}
        <div className="mx-10 my-4 lg:px-32 justify-end">
          <ChatInput
            onSend={handleSendMessage}
            disabled={processing}
            selectedModel={selectedModel}
            macState={macState}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
