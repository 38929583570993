import { useEffect, useState } from "react";
import { FaMicrophoneLines } from "react-icons/fa6";
import { MdSend } from "react-icons/md";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { starters } from "../hooks/constants";
import useKeys from "../hooks/useKeys";
import Card from "./Card";
import Tooltip from "./Tooltip";

export default function ChatInput({
  disabled,
  onSend,
  selectedModel,
  macState,
}) {
  const [message, setMessage] = useState("");
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [error, setError] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [rows, setRows] = useState(1);


  const handleReturnKey = () => {
    onSend(message);
    setMessage("");
  }

  const inputRef = useKeys(handleReturnKey);

  const startListening = () => {
    setError(null);
    resetTranscript();
    SpeechRecognition.startListening({
      continuous: true,
    });
    setIsListening(true);
  };
  const stopListening = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
    onSend(transcript);
    resetTranscript();
  };

  useEffect(() => {
    const calculateRows = () => {
      if (!message || message === "\n") {
        setMessage("");
        setRows(1);
        return;
      }

      const newlineCount = message.split('\n').length - 1; // Count newlines
      const lineCount = Math.min(Math.ceil(message.length / 50), 5);
      setRows(newlineCount + lineCount);
    };

    calculateRows();
  }, [message]);

  return (
    <div className="w-full flex flex-col">
      {/* Default Choices */}
      {!isListening && (
        <>
          {Object.entries(starters).map(([model, options]) => (
            selectedModel === model && (
              <div key={model} className="w-full flex mt-3 items-center gap-4 items-stretch">
                {options.map((option, index) => (
                  <Card
                    key={index}
                    content={option}
                    className="cursor-pointer w-1/2"
                    callback={onSend}
                  />
                ))}
              </div>
            )
          ))}
        </>
      )}

      <div className="w-full flex flex-row items-center space-between my-3">
        {!isListening && (
          <form
            className="min-w-80% w-full border-2 border-slate-400 rounded-lg p-2 flex flex-row m-2 mr-8 text-slate-50"
            onSubmit={(e) => {
              e.preventDefault();
              onSend(message);
              setMessage("");
            }}
            autoComplete="off"
          >
            <textarea
              rows={rows}
              ref={inputRef}
              cols="50"
              name="message"
              placeholder="Type your response ( Press 'Shift + Return' for a new line and 'Enter' to send)"
              className="w-full bg-slate-700 focus:outline-none p-1"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {
              <button
                disabled={disabled || !message}
                className={
                  "bg-pink-500 " +
                  "text-white " +
                  "font-bold " +
                  "py-2 px-4 " +
                  "rounded " +
                  "hover:bg-pink-700 " +
                  "disabled:bg-slate-600 " +
                  "disabled:text-slate-400"
                }
              >
                <MdSend />
              </button>
            }
          </form>
        )}

        {!isListening && (
          <Tooltip content="Start Talking">
            <button
              className="bg-pink-500 text-white font-bold py-4 px-4 rounded-full shadow-md shadow-pink-700/40 hover:shadow-none hover:bg-pink-600"
              onClick={() => startListening()}
            >
              <FaMicrophoneLines />
            </button>
          </Tooltip>
        )}
        {isListening && (
          <div className="w-full flex flex-row justify-center">
            <Tooltip content="Stop Talking">
              <button
                className="animate-pulse bg-pink-500 text-white text-2xl font-bold py-4 px-4 rounded-full shadow-md shadow-pink-700/40 hover:shadow-none hover:bg-pink-600"
                onClick={() => stopListening()}
              >
                <FaMicrophoneLines />
              </button>
            </Tooltip>
          </div>
        )}
        {error && <p className="text-red-500">Error: {error.message}</p>}
      </div>
    </div>
  );
}
