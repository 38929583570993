import React from 'react';

const Card = ({ icon, content, className = '', callback }) => {
    return (
        <div className={`bg-white rounded-lg shadow-md border border-gray-200 p-3 flex flex-col gap-2 ${className}`} onClick={() => { if (callback) callback(content) }}>
            {icon && (
                <div className="flex justify-center items-center text-lg">
                    {icon}
                </div>
            )}
            <p className="text-gray-700 font-semibold text-sm">{content}</p>
        </div>
    );
};

export default Card;
