const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const DEFAULT_MODEL = process.env.DEFAULT_MODEL;

const getSelectedModel = () => {
  let selectedModel = localStorage.getItem("selectedModel");
  return selectedModel !== null ? selectedModel : DEFAULT_MODEL;
};

export const createNewThread = async () => {
  try {
    let selectedModel = getSelectedModel();

    // Assuming your API expects a JSON payload with a model property
    let response = await fetch(`${BASE_URL}/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ model: selectedModel }),
    });
    return response.json();
  } catch (err) {
    console.error(err.message);
  }
};

export const fetchThread = async (threadId) => {
  try {
    let response = await fetch(`${BASE_URL}/threads/${threadId}`);
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchRun = async (threadId, runId) => {
  try {
    let response = await fetch(`${BASE_URL}/threads/${threadId}/runs/${runId}`);
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

export const postMessage = async (threadId, message) => {
  try {
    let selectedModel = getSelectedModel();

    let response = await fetch(`${BASE_URL}/threads/${threadId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: message, model: selectedModel }),
    });
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

export const postToolResponse = async (threadId, runId, toolResponses) => {
  try {
    let response = await fetch(
      `${BASE_URL}/threads/${threadId}/runs/${runId}/tool`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toolResponses),
      }
    );
    return response.json();
  } catch (err) {
    console.log(err.message);
  }
};

/**
 * Fetch speech from the server
 * @param {string} threadId The thread id to fetch speech for
 * @param {string} audioContent The audio content to send to the server
 * @returns {Promise<Blob>} A promise that resolves to a blob containing the speech
 */
export const fetchSpeech = async (threadId, audioContent) => {
  try {
    let response = await fetch(`${BASE_URL}/audio/${threadId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: audioContent }),
    });
    if (!response.ok) {
      console.log("Failed to fetch speech");
      return;
    }
    return response.blob();
  } catch (err) {
    console.log(err.message);
  }
};
