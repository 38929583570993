import React, { useState, useEffect } from "react";
import { HiChevronDown } from "react-icons/hi";
import { interactModels, modelMapping } from "../hooks/constants";

const SecondarySelector = ({ onModelChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedModel, setSelectedModel] = useState(interactModels.phone);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectModel = (model) => {
    onModelChange(model); // Call the passed callback function
    setSelectedModel(model);
    setIsOpen(false);
  };

  useEffect(() => {
    // Check for a model in local storage and set it as the selected model if found
    const modelFromStorage = localStorage.getItem("selectedModel");
    if (modelFromStorage) {
      setSelectedModel(modelFromStorage);
    }
  }, []);

  return (
    <div className="self-center">
      <button
        onClick={toggleDropdown}
        className="px-4 py-2 text-sm text-white secondary-menu rounded-md flex items-center"
      >
        Model:&nbsp; <b> {modelMapping[selectedModel]} </b>
        {/* Display the current selection */}
        <HiChevronDown className="ml-2 text-lg" />
      </button>
      {isOpen && (
        <div className="absolute width-full border border-orange-500 right-8 w-48 bg-white divide-y divide-gray-100 shadow-xl rounded-lg py-1 z-50">
          {Object.entries(interactModels).map(([key, value]) => (
            <a
              key={key}
              onClick={() => handleSelectModel(value)} // pass `value` if you want to display the model's name as selected
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
            >
              {modelMapping[value]}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default SecondarySelector;
