import React, { useState } from 'react';

const Tooltip = ({ children, content }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => setShowTooltip(true);
    const handleMouseLeave = () => setShowTooltip(false);

    return (
        <div className="relative">
            {children && (
                <span
                    className="cursor-pointer inline-block"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {children}
                </span>
            )}
            {showTooltip && (
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 py-1 px-2 rounded-md bg-slate-800 text-white w-32 text-center text-xs z-50">
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
