import { useEffect } from "react";
import { postToolResponse } from "../services/api";

export const useRunRequiredActionsProcessing = (
  run,
  setRun,
  setActionMessages
) => {
  useEffect(() => {
    if (run?.status === "requires_action") {
      let response = [];
      let actionMessages = [];
      for (const tool_call of run.required_action.submit_tool_outputs
        .tool_calls) {
        //function code if it exists
      }
      setActionMessages(actionMessages);
      postToolResponse(run.thread_id, run.run_id, response).then(setRun);
    }
  }, [run, setRun]);
};
